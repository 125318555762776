<div class="pages-404">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="pages-404-inner">
                    <h1>404</h1>
                    <h5>Page Not Found</h5>
                    <p>Sorry, the page you requested may have been moved or deleted</p>
                    <a class="btn btn-sm btn-theme" href="index.html">home page</a>
                </div>
            </div>
        </div>
    </div>
</div>