<div class="about-car">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <ngb-carousel [showNavigationArrows]="showNavigationArrows"
                    [showNavigationIndicators]="showNavigationIndicators">
                    <ng-template *ngFor="let item of images" ngbSlide>
                        <div class="picsum-img-wrapper">
                            <img [src]="item" alt="Random first slide">
                        </div>
                    </ng-template>
                </ngb-carousel>
            </div>
        </div>
    </div>
</div>