<div class="about-car">
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="about-slider-box simple-slider">
                    <img class="d-block w-100" src="assets/about.jpg" alt="about">
                </div>
            </div>
            <div class="col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div class="about-text">
                    <h3>Hakkımızda</h3>
                    <p>
                        İçerik eklenecek....
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>