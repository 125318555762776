import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  

  ngOnInit(): void {
    $(function () {

      'use strict';

      

      $(document).on('click', '.compare-btn', function () {
        if ($(this).hasClass('active')) {
          $(this).removeClass('active');
          $.jnoty("Car has been removed from compare list", {
            header: 'Warning',
            sticky: true,
            theme: 'jnoty-warning',
            icon: 'fa fa-check-circle'
          });

        } else {
          $(this).addClass('active');
          $.jnoty("Car has been added to compare list", {
            header: 'Success',
            sticky: true,
            theme: 'jnoty-success',
            icon: 'fa fa-check-circle'
          });
        }
      });

      // Header shrink while page scroll
      adjustHeader();
      doSticky();
      placedDashboard();
      $(window).on('scroll', function () {
        adjustHeader();
        doSticky();
        placedDashboard();
      });

      // Header shrink while page resize
      $(window).on('resize', function () {
        adjustHeader();
        doSticky();
        placedDashboard();
      });

      function adjustHeader() {
        var windowWidth = $(window).width();
        if (windowWidth > 0) {
          if ($(document).scrollTop() >= 100) {
            if ($('.header-shrink').length < 1) {
              $('.sticky-header').addClass('header-shrink');
            }
            if ($('.do-sticky').length < 1) {
              $('.company-logo img').attr('src', 'img/logos/black-logo.png');
            }
          }
          else {
            $('.sticky-header').removeClass('header-shrink');
            if ($('.do-sticky').length < 1 && $('.fixed-header').length == 0 && $('.fixed-header2').length == 0) {
              $('.company-logo img').attr('src', 'img/logos/logo.png');
            } else {
              $('.company-logo img').attr('src', 'img/logos/black-logo.png');
            }
          }
        } else {
          $('.company-logo img').attr('src', 'img/logos/black-logo.png');
        }
      }

      function doSticky() {
        if ($(document).scrollTop() > 40) {
          $('.do-sticky').addClass('sticky-header');
          //$('.do-sticky').addClass('header-shrink');
        }
        else {
          $('.do-sticky').removeClass('sticky-header');
          //$('.do-sticky').removeClass('header-shrink');
        }
      }

      function placedDashboard() {
        var headerHeight = parseInt($('.main-header').height(), 10);
        $('.dashboard').css('top', headerHeight);
      }
    });

  }
}
