import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.partial.component.html'
})
export class BannerComponent implements OnInit {

  banner: boolean = true;
  constructor(
  ) {}

  ngOnInit() {
    
  }

}
