import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './content/partial/footer.partial.component';
import { BannerComponent } from './content/partial/banner.partial.component';
import { HeaderComponent } from './content/partial/header.partial.component';
import { HomeComponent } from './content/pages/home.component';
import { NotFoundComponent } from './content/pages/not-found.component';
import { AboutComponent } from './content/pages/about.component';
import { ContactComponent } from './content/pages/contact.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GaleriComponent } from './content/pages/galeri.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    BannerComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    AboutComponent,
    ContactComponent,
    GaleriComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
