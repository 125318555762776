<div class="service-center content-area-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="inside-car service-car">
                    <iframe src="https://www.youtube.com/embed/MjIjGUlbgAM" allowfullscreen=""></iframe>
                </div>
            </div>
            <div class="col-lg-6 align-self-center">
                <div class="best-used-car">
                    <p>İçerik eklenecek...</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-section-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 align-self-center">
                <!-- Main title -->
                <div class="main-title">
                    <h1>Lüks ve Konsept Araçlarda Markayız!</h1>
                </div>
            </div>
            <div class="col-lg-7 offset-lg-1">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="service-info">
                            <div class="number">1</div>
                            <div class="icon">
                                <i class="flaticon-shield"></i>
                            </div>
                            <div class="detail">
                                <h3>Güvenilir</h3>
                                <p>İçerik eklenecek....</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="service-info">
                            <div class="number">2</div>
                            <div class="icon">
                                <i class="flaticon-deal"></i>
                            </div>
                            <div class="detail">
                                <h3>Dürüst</h3>
                                <p>İçerik eklenecek....</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="service-info">
                            <div class="number">3</div>
                            <div class="icon">
                                <i class="flaticon-car"></i>
                            </div>
                            <div class="detail">
                                <h3>Özel</h3>
                                <p>İçerik eklenecek....</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="service-info">
                            <div class="number">4</div>
                            <div class="icon">
                                <i class="flaticon-support-2"></i>
                            </div>
                            <div class="detail">
                                <h3>Her Zaman Yanınızda</h3>
                                <p>İçerik eklenecek....</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>