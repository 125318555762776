import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './content/pages/home.component';
import { NotFoundComponent } from './content/pages/not-found.component';
import { AboutComponent } from './content/pages/about.component';
import { ContactComponent } from './content/pages/contact.component';
import { GaleriComponent } from './content/pages/galeri.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'anasayfa',
    component: HomeComponent
  },
  {
    path: 'hakkimizda',
    component: AboutComponent
  },
  {
    path: 'galeri',
    component: GaleriComponent
  },
  {
    path: 'iletisim',
    component: ContactComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
