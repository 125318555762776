import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

    constructor(
        private title: Title,
    ) {
        this.title.setTitle("Regnum Motors Ankara - İletişim");
    }

    ngOnInit() {
    }

}
