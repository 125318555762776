import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-galeri',
    templateUrl: './galeri.component.html'
})
export class GaleriComponent implements OnInit {
    showNavigationArrows = true;
    showNavigationIndicators = true;
    images = [
        "assets/galeri/p1.jpg",
        "assets/galeri/p2.jpg",
        "assets/galeri/p3.jpg",
        "assets/galeri/b1.jpg",
        "assets/galeri/b2.jpg",
        "assets/galeri/x1.jpg",
        "assets/galeri/x2.jpg",
    ];
    constructor(
        private title: Title,
        config: NgbCarouselConfig
    ) {
        config.interval = 2000;
        this.title.setTitle("Regnum Motors Ankara - Galeri");
    }

    ngOnInit() {
    }

}
