<header class="top-header bg-active" id="top-header-2">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-8 col-sm-7">
                <div class="list-inline">
                    <a href="tel:1-8X0-666-8X88"><i class="fa fa-phone"></i>Yardıma mı ihtiyacınız var? 0 (312) 511 20 70</a>
                    <a href="tel:info@regnummotors.com.tr"><i class="fa fa-envelope"></i>info@regnummotors.com.tr</a>
                </div>
            </div>
        </div>
    </div>
</header>
<header class="main-header sticky-header sh-2">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" [routerLink]="['anasayfa']">
                <img src="assets/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                <span class="fa fa-bars"></span>
            </button>
            <div class="navbar-collapse collapse w-100" id="navbar">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link" [routerLink]="['anasayfa']">Anasayfa</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" target="_blank" href="https://regnummotors.sahibinden.com/">İlanlar</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" [routerLink]="['hakkimizda']">Hakkımızda</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" [routerLink]="['galeri']">Galeri</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link" [routerLink]="['iletisim']">İletişim</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>