<!-- Intro section start -->
<div class="intro-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 col-md-8 col-sm-12">
                <div class="intro-text">
                    <h3>Sorularınız mı var ?</h3>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12">
                <a [routerLink]="['iletisim']" class="btn btn-md">İLETİŞİME GEÇİN</a>
            </div>
        </div>
    </div>
</div>
<!-- Intro section end -->

<!-- Footer start -->
<footer class="footer overview-bgi">
    <div class="container footer-inner">
        <div class="row">
            <div class="col-lg-6">
                <div class="footer-item clearfix">
                    <img src="assets/logo.png" alt="logo">
                    <div class="s-border"></div>
                    <div class="m-border"></div>
                    <div class="text">
                        <P>
                            İçerik eklenecek....
                        </P>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="footer-item clearfix">
                    <h4>
                        İletişim Bilgileri
                    </h4>
                    <div class="s-border"></div>
                    <div class="m-border"></div>
                    <ul class="contact-info">
                        <li>
                            <i class="flaticon-pin"></i>İşçi Blokları Mah. Muhsin Yazıcıoğlu Cad. Regnum Sky Tower 57L Çankaya / ANKARA 
                        </li>
                        <li>
                            <i class="flaticon-mail"></i><a href="mailto:info@regnummotors.com.tr">info@regnummotors.com.tr</a>
                        </li>
                        <li>
                            <i class="flaticon-phone"></i><a href="tel:0 (312) 511 20 70 ">0 (312) 511 20 70 </a>
                        </li>
                        <li>
                            <i class="flaticon-phone"></i><a href="tel:0 (530) 243 89 89 ">0 (530) 243 89 89 </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2">
                <div class="footer-item">
                    <h4>
                        Sayfalar
                    </h4>
                    <div class="s-border"></div>
                    <div class="m-border"></div>
                    <ul class="links">
                        <li>
                            <a [routerLink]="['anasayfa']"><i class="fa fa-angle-right"></i>Anasayfa</a>
                        </li>
                        <li>
                            <a href="https://regnummotors.sahibinden.com/" target="_blank"><i class="fa fa-angle-right"></i>İlanlar</a>
                        </li>
                        <li>
                            <a [routerLink]="['hakkimizda']"><i class="fa fa-angle-right"></i>Hakkımızda</a>
                        </li>
                        <li>
                            <a [routerLink]="['iletisim']"><i class="fa fa-angle-right"></i>İletişim</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-8">
                    <p class="copy">© {{ year }} <a [routerLink]="['anasayfa']">Regnum
                            Motors.</a> Tüm hakları saklıdır.</p>
                </div>
                <div class="col-lg-4">
                    <div class="social-list-2">
                        <ul>
                            <li><a href="https://instagram.com/regnum.motors" target="_blank"><i class="fa fa-instagram"></i></a></li>
                            <li><a style="margin-left: 5px;" target="_blank" href="https://www.youtube.com/channel/UCDKeJNpyGB7UAbLhCpn5tkw"><i class="fa fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>