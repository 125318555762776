<div class="contact-2 content-area-5">
    <div class="container">
        <!-- Main title -->
        <div class="main-title text-center">
            <h1>İLETİŞİM</h1>
        </div>
        <form action="#" method="GET" enctype="multipart/form-data">
            <div class="row">
                <div class="col-lg-6">
                    <div height class="contact-info-2">
                        <div class="ci-box">
                            <div class="icon">
                                <i class="lnr lnr-map-marker"></i>
                            </div>
                            <div class="detail">
                                <h5>Adres</h5>
                                <p>
                                    İşçi Blokları Mah. Muhsin Yazıcıoğlu Cad. Regnum Sky Tower
                                    57L Çankaya / ANKARA
                                </p>
                            </div>
                        </div>
                        <div class="ci-box">
                            <div class="icon">
                                <i class="flaticon-phone"></i>
                            </div>
                            <div class="detail">
                                <h5>Telefon</h5>
                                <p>
                                    0 (312) 511 20 70
                                </p>
                                <p>
                                    0 (530) 243 89 89
                                </p>
                            </div>
                        </div>
                        <div class="ci-box">
                            <div class="icon">
                                <i class="flaticon-mail"></i>
                            </div>
                            <div class="detail">
                                <h5>ePosta:</h5>
                                <p>
                                    info@regnummotors.com.tr
                                    <br />
                                    <br />
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3061.244899621335!2d32.80867561582129!3d39.891148879429664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d345fd1b4485d5%3A0x2ba01aa0df6a7810!2sRegnum%20Sky%20Tower!5e0!3m2!1str!2str!4v1592052528611!5m2!1str!2str"
                            width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""
                            aria-hidden="false" tabindex="0"></iframe>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>